<template>
  <b-card title="Content">
    <draggable
      :list="topic.content"
      class="list-group list-group-flush"
      tag="ul"
      handle=".grab-button"
    >
      <transition-group type="transition" name="list">
        <app-collapse-item
          title=""
          v-for="(item, index) in topic.content"
          :key="item._id || index"
          class="drag-item border"
          :is-visible="index + 1 == topic.content.length ? true : false"
        >
          <template slot="header">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-grow-1
                w-100
              "
            >
              <div
                class="d-flex align-items-center w-100 grab-button"
                style="cursor: grab"
              >
                <feather-icon icon="TargetIcon" />
                <span
                  class="lead collapse-title ml-50 text-truncate d-block w-100"
                  style="max-width: 90%"
                >
                  Content {{ index + 1 }}
                </span>
              </div>
              <div style="position: absolute; right: 30px">
                <b-button
                  variant="flat-danger"
                  @click.stop="deleteContent(index)"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </div>
          </template>
          <conversation-card :conversations="item.conversations" />
          <question-card :content="item" />
        </app-collapse-item>
      </transition-group>
    </draggable>
    <div class="text-center">
      <b-button @click="addContent" variant="primary" size="sm">
        Add New
      </b-button>
    </div>
  </b-card>
</template>

<script>
import ConversationCard from "./ConversationCard.vue";
import QuestionCard from "./QuestionCard.vue";
import draggable from "vuedraggable";

export default {
  components: {
    ConversationCard,
    QuestionCard,
    draggable,
  },
  props: {
    topic: { type: Object, required: true },
  },
  methods: {
    addContent() {
      this.topic.content.push({
        conversations: [],
        questions: [],
      });
    },
    deleteContent(index) {
      this.topic.content.splice(index, 1);
    },
  },
};
</script>
