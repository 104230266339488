<template>
  <app-collapse type="shadow" class="border p-1">
    <div><h5>Conversation</h5></div>
    <draggable
      :list="conversations"
      class="list-group list-group-flush"
      tag="ul"
      handle=".grab-button"
    >
      <transition-group type="transition" name="list">
        <app-collapse-item
          title=""
          v-for="(item, index) in conversations"
          :key="item._id || index"
          class="drag-item my-1 border"
          :is-visible="index + 1 == conversations.length || item.duration < 1 ? true : false"
        >
          <template slot="header">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-grow-1
                w-100
              "
            >
              <div
                class="d-flex align-items-center w-100 grab-button"
                style="cursor: grab"
              >
                <feather-icon icon="TargetIcon" />
                <span
                  class="lead collapse-title ml-50 text-truncate d-block w-100"
                  style="max-width: 90%"
                >
                  Audio {{ index + 1 }}
                </span>
              </div>
              <div style="position: absolute; right: 30px">
                <b-button
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click.stop="deleteAudio(index)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </div>
          </template>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Audio" label-for="h-audio" label-cols-md="2">
                <b-row style="border-radius: 5px" class="border">
                  <b-col class="mt-1" md="8">
                    <ui-component
                      style="border-radius: 5px"
                      class="border mb-1"
                      :component-data="item.audio"
                    />
                  </b-col>
                  <b-col class="mt-50" md="4">
                    <b-form-group label="Duration" label-for="h-duration">
                      <b-form-input
                        type="number"
                        v-model="item.duration"
                        :options="type_positions"
                        :state="item.duration >= 1"
                      />
                      <b-form-invalid-feedback :state="item.duration >= 1">
                        Duration >= 1
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Caption"
                label-for="h-caption"
                label-cols-md="2"
              >
                <b-form-input
                  id="h-caption"
                  type="text"
                  v-model="item.caption"
                  placeholder="Caption"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="User Type"
                label-for="h-user-type"
                label-cols-md="2"
              >
                <b-form-select v-model="item.type_user" :options="type_users" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Avartar"
                label-for="h-avartar"
                label-cols-md="2"
              >
                <b-row style="border-radius: 5px" class="border">
                  <b-col class="custom-col" md="6">
                    <avartar-field class="mt-1" :component-data="item.image" />
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      class="mt-1"
                      label="Position Type"
                      label-for="h-position-type"
                    >
                      <b-form-select
                        v-model="item.type_position"
                        :options="type_positions"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </app-collapse-item>
      </transition-group>
    </draggable>
    <div class="text-center mt-4">
      <b-button @click="addConversation" variant="primary" size="sm">
        Add Conversation
      </b-button>
    </div>
  </app-collapse>
</template>
<script>
import audioSchema from "@core/layouts/components/input-fields/audio-field/defaultObject";
import imageSchema from "@core/layouts/components/input-fields/image-field/defaultObject";
import AvartarField from "./AvartarField.vue";
import defaultConstant from "../../defaultConstant";
import draggable from "vuedraggable";

export default {
  components: {
    AvartarField,
    draggable,
  },
  props: {
    conversations: { type: Array, required: true },
  },
  data() {
    return {
      type_users: [
        { value: defaultConstant.CHARACTER, text: "Character" },
        { value: defaultConstant.NARRATOR, text: "Narrator" },
      ],
      type_positions: [
        { value: defaultConstant.SHOW_LEFT, text: "Left" },
        { value: defaultConstant.SHOW_RIGHT, text: "Right" },
      ],
    };
  },
  methods: {
    addConversation() {
      this.conversations.push({
        type_position: 1,
        audio: { ...audioSchema, type: "audio" },
        duration: null,
        caption: "",
        image: { ...imageSchema, type: "image" },
        type_user: 1,
      });
    },
    deleteAudio(indexAudio) {
      this.conversations.splice(indexAudio, 1);
    },
  },
};
</script>
<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
}
</style>
