import api from '@/libs/axios'

export default {
  async getAll(params) {
    let response = await api.get('/admin/listening-topics', {
      params,
    })
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/listening-topics', payload)
    return response.data.data.new_document || null
  },
  async get(params) {
    let response = await api.get('/admin/listening-topics/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async update(payload) {
    let response = await api.put('/admin/listening-topics', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/listening-topics', {
      data: payload,
    })
    return response
  },
  async changeDisplayOrder(payload) {
    const response = await api.put('/admin/listening-topics/change-level', payload)
    return response
  },
}
